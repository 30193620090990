import React, { Component } from 'react';

class Resume extends Component {
  render() {
    const {skillmessage, education, work, backendSKill, frontendSkill, framework} = this.props.data ? this.props.data : "";
    let educations = education ? education.map(function(education){
            return <div key={education.school}><h3>{education.school}</h3>
            <p className="info">{education.degree} <span>&bull;</span><em className="date">{education.graduated}</em></p>
            <p>{education.description}</p></div>
          }) : "";
    let works = work ? work.map(function(work){
           let description = work.descriptions ? work.descriptions.map(function(desc){
               return <div>
                   <li>{desc}</li>
               </div>
           }) : ""

            return <div key={work.company}><h3>{work.company}</h3>
                <p className="info">{work.title}<span>&bull;</span> <em className="date">{work.years}</em></p>
                <p><ul>{description}</ul></p>
            </div>
          }) : "";
    let backend = backendSKill ? backendSKill.map(function(skills){
            let className = 'bar-expand '+skills.name.toLowerCase();
            return <div>
                <li key={skills.name}><span style={{width:skills.level}}className={className}></span><em>{skills.name} - {skills.years} years</em></li>
            </div>
          }) : "";

    let frontend = frontendSkill ? frontendSkill.map(function(skills){
        let className = 'bar-expand '+skills.name.toLowerCase();
        return <div>
            <li key={skills.name}><span style={{width:skills.level}}className={className}></span><em>{skills.name} - {skills.years} years</em></li>
        </div>
    }) : "";

    let frameworks = framework ? framework.map(function(library){
        let className = 'bar-expand '+library.name.toLowerCase();
        return <div>
            <li key={library.name}><span style={{width:library.level}}className={className}></span><em>{library.name} - {library.years} years</em></li>
        </div>
    }) : "";

    return (
      <section id="resume">

      <div className="row education">
         <div className="three columns header-col">
            <h1><span>Education</span></h1>
         </div>

         <div className="nine columns main-col">
            <div className="row item">
               <div className="twelve columns">
                 {educations}
               </div>
            </div>
         </div>
      </div>


      <div className="row work">

         <div className="three columns header-col">
            <h1><span>Work</span></h1>
         </div>

         <div className="nine columns main-col">
          {works}
        </div>
    </div>



      <div className="row skill">

         <div className="three columns header-col">
            <h1><span>Skills</span></h1>
         </div>

         <div className="nine columns main-col">

            <p>{skillmessage}
            </p>

				<div className="bars">
				   <ul className="skills">
                       <div>
                           <h2>Backend</h2>
                       </div>
                       <div style={{marginTop: 50}}>
					        {backend}
                       </div>
                       <div>
                           <h2>Frontend</h2>
                       </div>
                       <div style={{marginTop: 50}}>
                           {frontend}
                       </div>
					</ul>
				</div>
			</div>
      </div>
          <div className="row skill">

              <div className="three columns header-col">
                  <h1><span>Framework/Technologies</span></h1>
              </div>

              <div className="nine columns main-col" style={{marginTop: 20}}>

                  <p>{skillmessage}
                  </p>

                  <div className="bars">
                      <ul className="skills">
                          {frameworks}
                      </ul>
                  </div>
              </div>
          </div>
   </section>
    );
  }
}

export default Resume;
