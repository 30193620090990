import React, { Component } from 'react';

class Footer extends Component {
  render() {
    const {social} = this.props.data ? this.props.data : "";
    let networks =  this.props.data ? social.map(function(network){
        return <li key={network.name}><a href={network.url}><i className={network.className}></i></a></li>
    }) : "";


    return (
        <footer>
             <div className="row">
                <div className="twelve columns">
                   <ul className="social-links">
                      {networks}
                   </ul>

                   <ul className="copyright">
                      <span>Photo by <a
                          href="https://unsplash.com/@mischievous_penguins?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Casey Horner</a> on <a
                          href="https://unsplash.com/@mischievous_penguins?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">Unsplash</a></span>
                   </ul>

                </div>
                <div id="go-top"><a className="smoothscroll" title="Back to Top" href="#home"><i className="icon-up-open"></i></a></div>
             </div>
        </footer>
    );
  }
}

export default Footer;
