import React, { Component } from 'react';

class Header extends Component {
    render() {
        const {firstName, occupation, city, description, social} = this.props.data ? this.props.data : "";
        const networks = this.props.data ? social.map(function(network){
                    return <li key={network.name}><a href={network.url}><i className={network.className}></i></a></li>
                }) : "";

        return (
            <header id="home">

                <nav id="nav-wrap">

                    <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
                    <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>

                    <ul id="nav" className="nav">
                        <li className="current"><a className="smoothscroll" href="#home">Home</a></li>
                        <li><a className="smoothscroll" href="#about">About</a></li>
                        <li><a className="smoothscroll" href="#resume">Resume</a></li>
                        {/*<li><a className="smoothscroll" href="#portfolio">Works</a></li>*/}
                        <li><a className="smoothscroll" href="#testimonials">Recommendations</a></li>
                        <li><a className="smoothscroll" href="#contact">Contact</a></li>
                    </ul>

                </nav>

                <div className="row banner">
                    <div className="banner-text">
                        <h1 >Hi, my name is {firstName}.</h1>
                        <h3>A <span>{occupation}</span> living in the {city} area.</h3>
                        <h3>{description}</h3>
                        <hr />
                        <ul className="social">
                            {networks}
                        </ul>
                    </div>
                </div>

                <p className="scrolldown">
                    <a className="smoothscroll" href="#about"><i className="icon-down-circle"></i></a>
                </p>

            </header>
        );
    }
}

export default Header;