import './App.css';
import Header from "./Components/Header";
import React, {Component} from "react";
import About from "./Components/About";
import Resume from "./Components/Resume";
import Testimonials from "./Components/Testimonials";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";


class App extends Component {

    constructor(props){
        super(props);
        this.state = {};
    }

    getResumeData(){
        fetch('data.json').then((r) => r.json()).then(data => {
            this.setState({resumeData: data});
        })
    }

    componentDidMount(){
        this.getResumeData();
    }

    render() {
        return (
            <div className="App">
                <Header data={this.state.resumeData}/>
                <About data={this.state.resumeData}/>
                <Resume data={this.state.resumeData}/>
                <Testimonials data={this.state.resumeData}/>
                <Contact data={this.state.resumeData}/>
                <Footer data={this.state.resumeData}/>
            </div>
        );
    }
}

export default App;
